import ApolloClient, { gql } from 'apollo-boost';


export const getGamesList = () => {
  const client = new ApolloClient({
    uri: '/api',
    // uri: 'https://stage-se-gql.mystino.com/api',
  });

  // Game data call to fetch all categories and the games in the categories
  return client
    .query({
      query: gql`
      query gameData(
          $id: String!
          $brand: String!
          $market: String!
          $languageCode: String!
          $device: String!
        ) {
          gameList(
            id: $id
            brand: $brand
            market: $market
            languageCode: $languageCode
            device: $device
          ) {
            __typename
            id
            games {
              __typename
              id
              name
              vendor
              requiresLogin
              enabled
              categories
              devices
              thumbnailUrl
              backgroundUrl
              metaTitle
              metaDescription
              shortDescription
            }
          }
        }
    `,
      variables: {
        brand: 'mystino_fun',
        market: 'jp',
        languageCode: 'ja',
        device: 'desktop',
        id: 'mystino-fun-games'
      }
    })

}
export const getGameLaunchUrl = (gameId) => {
  const client = new ApolloClient({
    uri: 'https://gql.mystino.jp/api',
    headers:{
      "x-brand": "mystino_fun",
    }
    // uri: 'https://stage-se-gql.mystino.com/api',
  });

  // Game launch call, to get the game url, when starting a game
  return client
    .query({
      query: gql`
            query getGameLaunchUrl(
              $id: String!
              $launchMode: String!
              $languageCode: String!
              $currencyCode: String!
              $brand: String!
              $device: String!
            ) {
              gameLaunchUrl(
                id: $id
                launchMode: $launchMode
                languageCode: $languageCode
                currencyCode: $currencyCode
                brand: $brand
                device: $device
              ) {
                launchUrl
                jurisdiction
                isFlash
              }
            }
          `,
      variables: {
        id: gameId,
        launchMode: "fun",
        languageCode: "ja",
        currencyCode: "JPY",
        brand: "mystino_fun",
        device: "desktop"
      }
    })
}


