import React from 'react'
import {getGameLaunchUrl} from '../api/gamesApi'
import {Helmet} from "react-helmet";
import BackButton from "../components/BackButton";
import {FaExpandArrowsAlt} from 'react-icons/fa'
import {Link} from "react-router-dom";
import {FaTimes} from 'react-icons/fa'
import {Adsense} from '@ctrl/react-adsense'
import games from '../assets/social-games-lp.json'
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css'



const apiStatus = {
    notFetched: "notFetched",
    fetching: "fetching",
    fetched: "fetched",
    hasError: "hasError"
}



class Gamepage extends React.Component {
    gameId;

    constructor(props) {
        super(props);

        this.gameId = props.match.params.id;
        this.state = {
            gameDetails: {},
            gameUrl: {},
            gameUrlStatus: apiStatus.notFetched,
            errorMessage: "",
            addClass: false,
        }
        // addFreshchat(false);

    }


    toggle() {
        this.setState({addClass: !this.state.addClass});
    }

    componentDidMount() {
        this.setState({gameUrlStatus: apiStatus.fetching}); // se incarca api-ul -> loader

        getGameLaunchUrl(this.gameId)
            .then((response) => {
                this.setState({
                    gameUrl: response.data.gameLaunchUrl.launchUrl,
                    gameUrlStatus: apiStatus.fetched
                })
            })
            .catch((err) => {
                console.log("error. ".err)

                this.setState({
                    gameUrlStatus: apiStatus.hasError,
                    errorMessage: err.message
                })
            })

        // const games = JSON.parse(localStorage.getItem('games'))
        // if(games) {
            const gameDetails = games.find(element => element.id === this.gameId)
            this.setState({
                gameDetails: gameDetails,
            })

        // }else{
        //     this.setState({
        //         gameDetails: {
        //             metaTitle: "",
        //             metaDescription: "",
        //         },
        //     })
        // }
    }

    showIframe = () => {

        let toggleClass = ["toggle-full"];

        if(this.state.addClass) {
            toggleClass.push('active');
        }
        return (
            <div className="container">
                <div className={"btns-wrapper"}>
                    <div className="expand-wrapper">
                        <a className={'expand-game ' + toggleClass.join(' ')} onClick={this.toggle.bind(this)}><FaExpandArrowsAlt/></a>
                    </div>
                    <div className="close-wrapper">
                        <Link to={`/`} className="close-game"><FaTimes/></Link>
                    </div>
                </div>
                <div className={"iframe-game " + toggleClass.join(' ')}>
                    <div className="gads-left-right left-0">
                        <Adsense
                            client="ca-pub-8483773530544376"
                            slot="3269638939"
                        />
                    </div>

                    {/*<Link to={`/`} className="close-game"><FaTimes/></Link>*/}
                    <iframe
                        src={this.state.gameUrl}
                        frameBorder='0'
                        title='Mystino'
                    ></iframe>
                    {/*<a className={'expand-game ' + toggleClass.join(' ')} onClick={this.toggle.bind(this)}><FaExpandArrowsAlt/></a>*/}

                    <div className="gads-left-right right-0">
                        <Adsense
                            client="ca-pub-8483773530544376"
                            slot="4885972936"
                        />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let content = "";
        //console.log(this.state)

        if (this.state.gameUrlStatus === apiStatus.fetching || this.state.gameUrlStatus === apiStatus.notFetched) {
            content = <div className="loading">Loading...</div>;
        } else if (this.state.gameUrlStatus === apiStatus.fetched) {
            content = <div>
                {this.showIframe()}
                {/*<h1>{this.state.gameDetails.name}</h1>*/}
                {/*<p className="game-description">{this.state.gameDetails.shortDescription}</p>*/}
                <Helmet>
                    <title>
                        {this.state.gameDetails.name} - Mystino
                    </title>
                </Helmet>
            </div>;
        } else if (this.state.gameUrlStatus === apiStatus.hasError) {
            content = <div>An error has occured. {this.state.errorMessage}
                <Helmet>
                    <title>
                        {this.state.gameDetails.name} - Mystino
                    </title>
                </Helmet>
            </div>;
        }

        return (
            <div className="game__m__container">
                <div className='gameplay__container' style={{color: 'white'}}>
                    {content}
                </div>
                <div className="info-game">
                    <h1>{this.state.gameDetails.name}</h1>
                    <p className="game-description">{this.state.gameDetails.shortDescription}</p>
                    <BackButton/>
                </div>


                <div className="gads-mobile">
                    <Adsense
                        client="ca-pub-8483773530544376"
                        slot="4885972936"
                    />
                </div>
                <Freshchat token='e6f4c493-8ef0-42d1-836a-0007b3afd0eb' host='https://wchat.eu.freshchat.com'
                           locale='ja' tags={['mystino-fp']} />
            </div>
        )
    }
}

export default Gamepage
