import React from 'react'
import logo from '../assets/imgs/logo_new_mystino.svg'
import GameProducer from "../components/GameProducer";
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

const owlCarouselOptions = {
    items: 5,
    margin: 5,
    stagePadding: 20,
    nav: false,
    rewind: true,
    autoplay: true,
    responsive: {
        0: {
            items: 2,
            nav: true
        },
        720: {
            items: 2,
            nav: true
        },
        1000: {
            items: 5,
            nav: true
        },
    },
}

const gameProducers = [
    {
        image: require('../assets/imgs/game_producers/netent.png'),
        title: 'NETENT',
        link: '/netent'
    },
    {
        image: require('../assets/imgs/game_producers/yggdrasil-gaming.png'),
        title: 'Evolution Gaming',
        link: '/evolution'
    },
    {
        image: require('../assets/imgs/game_producers/jtg-logo.png'),
        title: 'JTG',
        link: '/jtg'
    },
    {
        image: require('../assets/imgs/game_producers/playgo.png'),
        title: "Play'n Go",
        link: '/playgo'
    },
    {
        image: require('../assets/imgs/game_producers/microgaming.png'),
        title: 'Blaze of Ra',
        link: 'blazeofra'
    }
]

const Footer = () => {
    let showGameProducers;
    showGameProducers = () => {
        window.getSelection().empty()
        return gameProducers.map((elem, index) => (
            <GameProducer
                key={index}
                image={elem.image}
                link={elem.link}
            />
        ))
    }

    return (
        <footer>
            <div className='stores'>
                {/*<img src={gplayIcon} alt='googleplay' />*/}
                {/*<img src={appstoreIcon} alt='appstore' />*/}
            </div>
            <div className='footer__logo'>
                <img src={logo} alt="logo" />
            </div>
            {/*<div className="border-b-1"></div>*/}
            <div className='footer__desktop'>
                <div className='logo-container'>
                   <img src={logo} alt="logo" width="150px" />
                </div>
                {/*<div className={"w-180"}>*/}
                    {/*<img src={gplayIcon} alt='googleplay' />*/}
                {/*</div>*/}
                {/*<div className={"w-180"}>*/}
                    {/*<img src={appstoreIcon} alt='appstore' />*/}
                {/*</div>*/}
            </div>

            <div className='d-flex flex-wrap'>
                <div className="home-text-container col-sm-12 col-md-7 col-lg-7 ml-auto mr-auto">
                    <div className="text-center font-size-m">
                        <h4 className="gameproducers__container--title text-center">ミスティーノ無料カジノゲーム</h4>
                        <p className="mb-4">
                            無料でのカジノゲームはお好きですか？そんなあなたにぴったり！いつでもどこでも遊びたいときに、お気に入りのカジノゲームをミスティーノでプレイすることができます。PCでもスマホでもタブレットでも、どれでもOK。ミスティーノのアプリをダウンロードして、遊びたいゲームをお好きなところでお好きなときにプレイしましょう！
                        </p>
                        <p className="mb-4">
                            ミスティーノカジノゲームを無料でプレイするのはとてもシンプルで簡単。たくさんのゲームの中から好きなゲームを選んでプレイするだけ！ミスティーノは日本人プレイヤーが遊びやすいようにデザインされており、素晴らしいゲーム体験があなたを待っています。いつでもどこでも楽しめます！
                        </p>
                        <p  className="mb-4">
                            魔法の世界ミスティーノで、お気に入りのカジノゲームを無料で遊ぼう！始めるなら今がベスト。今すぐプレイ！
                        </p>

                        <div className="m-0">   会社名: Alcanada Media Partners Ltd</div>
                        <div className="m-0">   会社住所: Suite 3 Second Floor, Icom House, 1/5 Irish Town, Gibraltar</div>
                        <div className="mb-4">   メールアドレス: <a href="mailto:contact@alcanadamedia.com">contact@alcanadamedia.com</a></div>

                        <p className="mb-4">ミスティーノをご利用頂きありがとうございます。<br/>
                            ご意見やご質問は以下のメールアドレスまでお願い致します。<br/>
                            <a className="text-decoration-underline" href="mailto:cinfo@mystino.jp">info@mystino.jp</a> </p>

                        <p className="mb-4">サイト内のゲームは全て無料で遊べます。本サイト内に有料課金コンテンツは含みません。</p>
                        <p className="pt-2 pb-2">ミスティーノ無料版は20歳以上の方の利用を推奨しています。</p>

                        <div className="privacy-policy"><a href="/privacy-policy-ja">個人情報保護方針</a> <a className="ml-4" href="/privacy-policy-en">Privacy Policy</a></div>
                        <div style={{ padding: '1rem' }}>© 2024 Mystino</div>
                    </div>
                </div>
            </div>


        </footer>
    )
}

export default Footer
